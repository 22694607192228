import React from "react";

function AccordionOne() {
	return (
		<div className="flex flex-col items-center text-center">
			<p className="text-lg mb-4">
				The best way to contact Polar&nbsp;Water is by Phone Consultation. <br />However,
				we welcome text messaging and emails as well.
			</p>
			<div className="flex flex-col items-center">
				<div className="hidden sm:flex flex-col text-gray-800 items-center">
					<a href="tel:+19512125633" className="hover:text-red-700 hover:underline text-lg">
						<i className="fas fa-phone mr-2"></i>
						+1 (951) 212-5633
					</a>
					<a href="mailto:Polarwaterllc@gmail.com" className="hover:text-red-700 hover:underline text-lg mt-2">
						<i className="fas fa-envelope mr-2"></i>
						Polarwaterllc@gmail.com
					</a>
				</div>
				<div className="flex sm:hidden flex-col text-gray-800 items-center mt-5">
					<a href="tel:+19512125633" className="text-red-700 hover:underline text-lg">
						<i className="fas fa-phone mr-2"></i>
						+1 (951) 212-5633
					</a>
					<a href="mailto:Polarwaterllc@gmail.com" className="text-red-700 hover:underline text-lg mt-2">
						<i className="fas fa-envelope mr-2"></i>
						Polarwaterllc@gmail.com
					</a>
				</div>
			</div>
		</div>
	);
}

export default AccordionOne;
