import React from "react";
import hwOne from "../../../images/landingPage/HardWaterSection/hw1.png";
import hwTwo from "../../../images/landingPage/HardWaterSection/hw2.png";
import hwThree from "../../../images/landingPage/HardWaterSection/hw3.png";
import hwFour from "../../../images/landingPage/HardWaterSection/hw4.png";
import hwFive from "../../../images/landingPage/HardWaterSection/hw5.png";

function HardWaterCarousel() {
	// Array to hold the slides
	const slides = [
		{ id: 1, src: hwOne, alt: "Hard Water 1" },
		{ id: 2, src: hwTwo, alt: "Hard Water 2" },
		{ id: 3, src: hwThree, alt: "Hard Water 3" },
		{ id: 4, src: hwFour, alt: "Hard Water 4" },
        { id: 5, src: hwFive, alt: "Hard Water 5" },
	];

	return (
		<React.Fragment>
			<div id="CAROUSEL" className="relative overflow-hidden">
				<div
					data-hs-carousel='{
						"loadingClasses": "opacity-0",
						"dotsItemClasses": "hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border border-gray-400 rounded-full cursor-pointer",
						"isAutoPlay": true,
						"itemsPerView": 1
					}'
					className="relative"
				>
					<div className="hs-carousel relative overflow-hidden w-full bg-white rounded-lg">
						<div className="hs-carousel-body flex transition-transform duration-700 ease-in-out">
							{/* Map through the slides array */}
							{slides.map((slide) => (
								<div key={slide.id} className="hs-carousel-slide max-h-[500px] w-full flex-shrink-0">
									<div className="flex justify-center h-full bg-gray-200 p-6">
										<img
											src={slide.src}
											alt={slide.alt}
											className="w-full h-auto object-contain rounded-lg shadow-lg"
										/>
									</div>
								</div>
							))}
						</div>
					</div>

					{/* Previous Button */}
					<button
						type="button"
						className="hs-carousel-prev absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-s-lg"
					>
						<span className="text-2xl" aria-hidden="true">
							<svg
								className="shrink-0 size-5"
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path d="m15 18-6-6 6-6" />
							</svg>
						</span>
						<span className="sr-only">Previous</span>
					</button>

					{/* Next Button */}
					<button
						type="button"
						className="hs-carousel-next absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-e-lg"
					>
						<span className="sr-only">Next</span>
						<span className="text-2xl" aria-hidden="true">
							<svg
								className="shrink-0 size-5"
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path d="m9 18 6-6-6-6" />
							</svg>
						</span>
					</button>

					{/* Pagination */}
					<div className="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2" />
				</div>
			</div>
		</React.Fragment>
	);
}

export default HardWaterCarousel;
