import React from "react";
import water from "../../images/landingPage/IntroSection/boyDrinkingWater.png";

function IntroSection() {
	return (
		<React.Fragment>
			<div className="container mx-auto p-4 relative">
				<div className="overflow-hidden relative w-full h-full my-8">
					<div className="flex flex-col items-center space-y-8 order-1 md:order-none">
						{/* Title */}
						<h1
							id="TITLE"
							className="text-4xl font-extrabold font-serif uppercase text-gray-800"
						>
							Get the Water You Deserve with Polar&nbsp;Water
						</h1>

						{/* Line */}
						<div id="LINE" className="w-3/4 md:w-2/3 xl:w-3/5 h-1 bg-red-700"></div>
					</div>
				</div>
				<div className="flex flex-col lg:flex-row space-y-8">
					<div id="IMAGE" className="lg:w-1/2 md:mb-4 mt-8 md:mt-0 pe-4">
						<img
							src={water}
							alt="Clean Water"
							className="w-full h-full rounded-lg shadow-lg"
						/>
					</div>
					<div
						id="div1"
						className="lg:w-1/2 items-center justify-start p-4"
					>
						<div className="space-y-8 pe-8">
							<p className="text-gray-600 text-lg text-center lg:text-left ">
								Your home is your sanctuary, and the water you provide for your
								family should reflect that care. With Polar&nbsp;Water, you’re giving
								them more than just clean water—you’re offering safety, health,
								and peace of mind. Our system protects your loved ones from
								harmful contaminants, ensures your appliances last longer, and
								guarantees that every drop is as pure as your intention. Let
								Polar Water be your partner in safeguarding what truly matters:
								the well-being of your home and the people you love.
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default IntroSection;
