import React from "react";
import water from "../../images/landingPage/IntroSection/water2.jpg";

function IntroSection() {
	return (
		<React.Fragment>
			<div className="container mx-auto p-4 px-8 mb-8 mt-8 md:mt-0">
				<div className="w-full h-full">
					<div className="relative mt-8 flex flex-col md:block space-y-8">
						{/* Image */}
						<div
							id="IMAGE"
							className="md:w-1/2 md:float-right lg:ml-4 md:mb-4 mt-8 md:mt-0 ps-4 order-2 md:order-none"
						>
							<img
								src={water}
								alt="Clean Water"
								className="w-full h-auto rounded-lg shadow-lg"
							/>
						</div>

						{/* Centered Title and Line */}
						<div className="flex flex-col items-center space-y-8 order-1 md:order-none">
							{/* Title */}
							<h1
								id="TITLE"
								className="text-4xl font-extrabold font-serif uppercase text-gray-800"
							>
								About Polar&nbsp;Water
							</h1>

							{/* Line */}
							<div id="LINE" className="w-3/4 md:w-3/5 lg:w-2/3 xl:w-3/5 xl:w-1/2 h-1 bg-red-700"></div>
						</div>

						{/* Content */}
						<p className="text-gray-600 text-lg text-left md:mt-0 order-3 md:order-none">
							At Polar&nbsp;Water, we do things differently. Our mission is to
							provide your home with clean, contaminant-free, filtered water
							through a service built on transparency, honesty, and integrity.
							We test your water to deliver tailored filtration, ensuring every
							home gets a personalized solution. Our systems provide safe, fresh
							water to every faucet and can adapt to new contaminants with
							replaceable filters and media. With no pushy sales tactics or
							hidden pricing, we focus on delivering dependable, customer-first
							support, ensuring long-lasting satisfaction and trust.
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default IntroSection;
