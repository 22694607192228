import React from "react";
import Acc1 from "./AccordionOne.jsx";
import Acc2 from "./AccordionTwo.jsx";
import Water from "../../images/products/WaterSpread.png";

function ContactUs() {
    return (
        <React.Fragment>
            {/* Title and Banner section */}
            <div className="relative block overflow-hidden dark:focus:outline-none">
                <div className="aspect-w-12 aspect-h-7 sm:aspect-none overflow-hidden">
                    <img
                        className="w-full h-80 object-cover"
                        src={Water}
                        alt="Water Filtration Systems"
                    />
                </div>
                <div className="absolute bottom-48 sm:bottom-52 lg:start-28 md:start-16 p-2">
                    <div className="p-3 text-4xl font-bold text-black">Contact Us</div>
                </div>
            </div>

            {/* Grid Layout */}
            <div className="container mx-auto p-4 lg:px-40">
                <div className="py-20">
                    <Acc1 />
                    <br /><br />
                    <Acc2 />
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactUs;
