import React, { useState } from "react";
import axios from "axios";

function AccordionTwo() {
	const [customerInfo, setCustomerInfo] = useState({
		firstName: "",
		lastName: "",
		zipcode: "",
		email: "",
		phoneNum: "",
		contactMethod: "Text", // Default value
		bestTime: "Morning", // Default value
		message: "",
	});

	// Handle input changes
	const handleChange = (e) => {
		const { id, name, value, type } = e.target;
		// Radio buttons use 'name' while other inputs use 'id'
		const key = type === "radio" ? name : id;

		console.log(e);
		setCustomerInfo({
			...customerInfo,
			[key]: value, // Dynamically setting form fields based on the input type
		});
	};

	// Handle form submit
	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submission

		console.log(customerInfo);
		try {
			// Send POST request to the Netlify function
			await axios.post(
				"https://polarwaterllc.com/.netlify/functions/Sendemail",
				customerInfo,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			alert("Email sent successfully!");
		} catch (error) {
			console.error("There was an error sending the email!", error);
			alert("Failed to send email.");
		}
	};

	function formatPhoneNumber(value) {
		// Remove all non-digit characters
		const cleaned = value.replace(/\D/g, "");

		// Apply formatting: (123) 456-7890
		let formatted = cleaned;
		if (cleaned.length > 3 && cleaned.length <= 6) {
			formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
		} else if (cleaned.length > 6) {
			formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
				3,
				6
			)}-${cleaned.slice(6, 10)}`;
		} else if (cleaned.length > 0) {
			formatted = `(${cleaned}`;
		}

		return formatted;
	}

	return (
		<React.Fragment>
			<p className="text-center text-lg mb-4">
				To schedule a Phone Consultation with Polar&nbsp;Water, please fill out
				and submit the following form:
			</p>
			<form id="FORMTHREE" className="max-w-2xl mx-auto space-y-6">
				<p className="text-red-700 text-sm">
					Fields with an asterisk are required.
				</p>
				<div id="NAME" className="flex flex-col md:flex-row gap-4">
					<div className="flex-1">
						<label
							className="block text-sm font-semibold mb-1"
							htmlFor="firstName"
						>
							First Name <span className="text-red-700">*</span>
						</label>
						<input
							type="text"
							id="firstName"
							className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
							placeholder="Jane"
							value={customerInfo.firstName}
							onChange={handleChange}
						/>
					</div>
					<div className="flex-1 md:ml-4">
						<label
							className="block text-sm font-semibold mb-1"
							htmlFor="lastName"
						>
							Last Name <span className="text-red-700">*</span>
						</label>
						<input
							type="text"
							id="lastName"
							className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
							placeholder="Doe"
							value={customerInfo.lastName}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div id="INFO" className="flex flex-col md:flex-wrap md:gap-4">
					<div className="w-full mb-4">
						<label className="block text-sm font-semibold mb-1" htmlFor="email">
							Email <span className="text-red-700">*</span>
						</label>
						<input
							type="email"
							id="email"
							className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
							placeholder="janedoe@aol.com"
							value={customerInfo.email}
							onChange={handleChange}
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-4">
						<div className="flex-1">
							<label
								className="block text-sm font-semibold mb-1"
								htmlFor="phoneNum"
							>
								Phone <span className="text-red-700">*</span>
							</label>
							<input
								type="text"
								id="phoneNum"
								className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
								required
								placeholder="(123) 456-7890"
								value={customerInfo.phoneNum}
								onChange={(e) => {
									const formattedPhone = formatPhoneNumber(e.target.value);
									handleChange({
										target: { id: "phoneNum", value: formattedPhone },
									});
								}}
							/>
						</div>
						<div className="flex-1">
							<label
								className="block text-sm font-semibold mb-1"
								htmlFor="zipcode"
							>
								Zip Code <span className="text-red-700">*</span>
							</label>
							<input
								type="text"
								id="zipcode"
								maxLength="5"
								className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
								required
								placeholder="12345"
								value={customerInfo.zipcode}
								onChange={(e) => {
									const regex = /^[0-9]*$/;
									if (regex.test(e.target.value)) {
										handleChange(e);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div id="SELECTS" className="flex flex-col md:flex-row gap-4">
					<div className="flex-1">
						<label
							className="block text-sm font-semibold mb-1"
							htmlFor="contactMethod"
						>
							Preferred Method of Contact{" "}
							<span className="text-red-700">*</span>
						</label>
						<select
							className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
							id="contactMethod"
							value={customerInfo.contactMethod}
							onChange={handleChange}
						>
							<option>Text</option>
							<option>Call</option>
							<option>Email</option>
						</select>
					</div>
					<div className="flex-1">
						<label
							className="block text-sm font-semibold mb-1"
							htmlFor="bestTime"
						>
							Best Time to Contact <span className="text-red-700">*</span>
						</label>
						<select
							className="mt-0 block w-full text-center rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
							id="bestTime"
							value={customerInfo.bestTime}
							onChange={handleChange}
						>
							<option>Morning</option>
							<option>Mid-Day</option>
							<option>Evening</option>
						</select>
					</div>
				</div>
				<div id="MESSAGE">
					<label className="block text-sm font-semibold mb-1" htmlFor="message">
						Message
					</label>
					<textarea
						className="mt-0 block w-full rounded-bl border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
						rows="4"
						id="message"
						value={customerInfo.message}
						onChange={handleChange}
					></textarea>
				</div>
				<div id="BUTTON" className="w-auto px-3">
					<button
						type="submit"
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
						onClick={handleSubmit}
					>
						Submit
					</button>
				</div>
			</form>
			{/* <form id="FORMONE" className="space-y-4" onSubmit={handleSubmit}>
				<div className="flex flex-wrap -mx-3">
					<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="firstName"
						>
							First Name
						</label>
						<input
							className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
							id="firstName"
							type="text"
							placeholder="Jane"
							value={customerInfo.firstName}
							onChange={handleChange}
						/>
					</div>
					<div className="w-full md:w-1/2 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="lastName"
						>
							Last Name
						</label>
						<input
							className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
							id="lastName"
							type="text"
							placeholder="Doe"
							value={customerInfo.lastName}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="flex flex-wrap -mx-3">
					<div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="zipcode"
						>
							Zipcode
						</label>
						<input
							className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
							id="zipcode"
							type="text"
							placeholder="12345"
							value={customerInfo.zipcode}
							onChange={handleChange}
						/>
					</div>
					<div className="w-full md:w-3/4 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="contact"
						>
							Phone Number or Email
						</label>
						<input
							className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
							id="contact"
							type="text"
							placeholder="Your contact information"
							value={customerInfo.contact}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="flex flex-wrap -mx-3 items-center">
					<div className="w-full md:w-2/5 px-3 mb-6 md:mb-0 flex items-center">
						<fieldset className="w-full">
							<legend className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
								Preferred Method of Contact
							</legend>
							<div className="flex justify-center space-x-4">
								<label className="inline-flex items-center">
									<input
										type="radio"
										className="form-radio"
										name="contactMethod" // Use "name" for radio buttons
										value="text"
										checked={customerInfo.contactMethod === "text"}
										onChange={handleChange}
									/>
									<span className="ml-2">Text</span>
								</label>
								<label className="inline-flex items-center">
									<input
										type="radio"
										className="form-radio"
										name="contactMethod"
										value="call"
										checked={customerInfo.contactMethod === "call"}
										onChange={handleChange}
									/>
									<span className="ml-2">Call</span>
								</label>
								<label className="inline-flex items-center">
									<input
										type="radio"
										className="form-radio"
										name="contactMethod"
										value="email"
										checked={customerInfo.contactMethod === "email"}
										onChange={handleChange}
									/>
									<span className="ml-2">Email</span>
								</label>
							</div>
						</fieldset>
					</div>
					<div className="w-full md:w-2/5 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="bestTime"
						>
							Best Time to Contact
						</label>
						<select
							className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="bestTime"
							value={customerInfo.bestTime}
							onChange={handleChange}
						>
							<option>Morning</option>
							<option>Mid-Day</option>
							<option>Evening</option>
						</select>
					</div>
					<div className="w-full md:w-1/5 px-3">
						<button
							type="submit"
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
						>
							Submit
						</button>
					</div>
				</div>
			</form> */}
			{/* <form id="FORMTWO" className="max-w-2xl mx-auto space-y-6">
				<p className="text-red-700 text-sm">
					Fields with an asterisk are required.
				</p>
				<div id="NAME" className="flex flex-wrap gap-4">
					<div className="flex-1">
						<label className="block text-sm font-semibold mb-1" htmlFor="firstName">
							First Name <span className="text-red-700">*</span>
						</label>
						<input
							type="text"
                            id="firstName"
							className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
                            placeholder="Jane"
							value={customerInfo.firstName}
							onChange={handleChange}
						/>
					</div>
					<div className="flex-1">
						<label className="block text-sm font-semibold mb-1" htmlFor="lastName">
							Last Name <span className="text-red-700">*</span>
						</label>
						<input
							type="text"
                            id="lastName"
							className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
                            placeholder="Doe"
							value={customerInfo.lastName}
							onChange={handleChange}
						/>
					</div>
				</div>				
				<div id="INFO" className="flex flex-wrap gap-4">
					<div className="flex-1">
						<label className="block text-sm font-semibold mb-1" htmlFor="email">Email <span className="text-red-700">*</span></label>
						<input
							type="email"
                            id="email"
							className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
                            placeholder="janedoe@aol.com"
							value={customerInfo.email}
							onChange={handleChange}
						/>
					</div>
					<div className="flex-1">
						<label className="block text-sm font-semibold mb-1" htmlFor="phoneNum">Phone <span className="text-red-700">*</span></label>
						<input
							type="text"
                            id="phoneNum"
							className="mt-0 block text-center w-full rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
							required
                            placeholder="(123) 456-7890"
							value={customerInfo.phoneNum}
							onChange={handleChange}
						/>
					</div>
					<div className="flex-1">
						<label className="block text-sm font-semibold mb-1" htmlFor="zipcode">
							Zip Code <span className="text-red-700">*</span>
						</label>
						<input
							type="text"
                            id="zipcode"
							className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
                            required
                            placeholder="12345"
							value={customerInfo.zipcode}
							onChange={handleChange}
						/>
					</div>
				</div>
                <div id="SELECTS" className="flex flex-wrap gap-4">
					<div className="flex-1">
                        <label className="block text-sm font-semibold mb-1" htmlFor="contactMethod">Preferred Method of Contact <span className="text-red-700">*</span></label>
                        <select
                            className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
                            required
                            id="contactMethod"
                            value={customerInfo.contactMethod}
                            onChange={handleChange}
                        >
                            <option>Text</option>
                            <option>Call</option>
                            <option>Email</option>
                        </select>
				    </div>
                    <div className="flex-1">
                        <label className="block text-sm font-semibold mb-1" htmlFor="bestTime">Best Time to Contact <span className="text-red-700">*</span></label>
                        <select
                            className="mt-0 block w-full text-center rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
                            required
                            id="bestTime"
                            value={customerInfo.bestTime}
                            onChange={handleChange}
                        >
                            <option>Morning</option>
                            <option>Mid-Day</option>
                            <option>Evening</option>
                        </select>
                    </div>
                </div>
				<div id="MESSAGE">
					<label className="block text-sm font-semibold mb-1" htmlFor="message">Message</label>
					<textarea
						className="mt-0 block w-full rounded-md border-0 border-b-2 border-gray-700 px-0.5 focus:border-red-700 focus:ring-0"
						rows="4"
                        id="message"
                        value={customerInfo.message}
                        onChange={handleChange}						
					></textarea>
				</div>
				<div id="BUTTON" className="w-full md:w-1/5 px-3">
						<button
							type="submit"
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
						>
							Submit
						</button>
					</div>
			</form> */}
		</React.Fragment>
	);
}

export default AccordionTwo;
