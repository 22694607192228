import React from "react";
import { Link } from "react-router-dom";

function LrgCntForm() {
	return (
			<React.Fragment>
            <div className="row-start-4 col-span-4 row-span-1 flex flex-col justify-end">
                <div className="lg:mb-20 xl:mb-32 2xl:mb-20 w-full">
                    <Link
                        to="/contactUs"
                        className="items-start"
                    >
                        <button type="button" className="py-3 px-4 inline-flex gap-x-2 md:text-2xl font-semibold rounded-lg border border-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 text-white hover:bg-blueOne disabled:opacity-50 disabled:pointer-events-none">
                        Get a Free Quote
                        </button>
                    </Link>
                </div>
            </div>
        </React.Fragment>
	);
}

export default LrgCntForm;



<div id="BUTTON" className="col-span-5 row-span-3 flex justify-center items-center">
                    <Link to="/contactUs">
                        <button type="button" className="py-4 px-6 inline-flex items-center gap-x-2 text-sm md:text-5xl font-semibold rounded-lg border border-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 text-white hover:bg-blueOne disabled:opacity-50 disabled:pointer-events-none">
                        Schedule a Phone Consultation
                        </button>
                    </Link>
                </div>