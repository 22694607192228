import ReactPixel from 'react-facebook-pixel';

// Facebook pixel initialization 
const initFacebookPixel = () => {
  ReactPixel.init('YOUR_PIXEL_ID');
  ReactPixel.pageView(); // Track the initial page load
};

const trackPageView = () => {
  ReactPixel.pageView(); // Call this on route changes
};

export { initFacebookPixel, trackPageView };