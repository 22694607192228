import React from "react";
import { Link } from "react-router-dom";
import HardWaterCarousel from "./HWCarousel";

function HardWaterSection() {
	return (
		<React.Fragment>
			<div className="container mx-auto p-4 md:px-8">
				<div className="w-full h-full my-8">
					<div className="flex flex-col items-center space-y-8 order-1 md:order-none">
						{/* Title */}
						<h1
							id="TITLE"
							className="text-4xl font-extrabold font-serif uppercase text-gray-800"
						>
							What is Hard Water?
						</h1>

						{/* Line */}
						<div id="LINE" className="w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 2xl:w-1/4 h-1 bg-red-700"></div>
					</div>
				</div>
				<div className="lg:w-3/4 mx-auto mb-8">
					<HardWaterCarousel />
				</div>
				<div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0">
					<div className="lg:w-1/2 pe-4">
						<p className=" text-gray-600 text-lg text-center lg:text-left ">
							Hard water contains high levels of dissolved minerals, primarily
							calcium and magnesium. In fact, over 85% of U.S. homes suffer from
							hard water, including around 12 million households in California.
							Hard water leads to scale buildup in pipes, appliances, and
							fixtures, reducing their efficiency and lifespan. You may notice
							issues like dry skin, dull hair, and spots on dishes and
							glassware. Over time, the minerals in hard water leave a residue
							that clogs pipes and restricts water flow.
						</p>
					</div>
					<div className="lg:w-1/2 ps-4">
						<p className=" text-gray-600 text-lg text-center lg:text-left ">
							With Polar&nbsp;Water’s advanced filtration system, not only do we
							prevent hard water scale from forming, but we also descale,
							removing the existing buildup from your pipes, fixtures, and
							appliances. This keeps your home’s water flowing smoothly and
							extends the life of your plumbing and appliances.
						</p>
					</div>
				</div>
					<div className="justify-center my-8">
						<Link to="/contactUs">
							<button
								type="button"
								className="mt-4 py-3 px-4 inline-flex items-center text-lg font-semibold rounded-lg border border-transparent blueOne text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
							>
								Get a Free In-Home Water Test
							</button>
						</Link>
					</div>
			</div>
		</React.Fragment>
	);
}

export default HardWaterSection;
