import React from "react";
import Two from "../../images/landingPage/guaranteeSection/Two.png"
import Three from "../../images/landingPage/guaranteeSection/Three.png"
import Four from "../../images/landingPage/guaranteeSection/Four.png"
import Five from "../../images/landingPage/guaranteeSection/Five.png"

function GuaranteeSection() {
	const images = [
		{ id: 1, src: Two, alt: "Annual Water Testing" },
		{ id: 2, src: Three, alt: "Hassle-Free Support" },
		{ id: 3, src: Four, alt: "Satisfaction Guarantee" },
		{ id: 4, src: Five, alt: "A Relationship for Life" },
	];

	return (
		<React.Fragment>
			<div className="container mx-auto p-4 mb-8 xl:mb-0">
				<div className="w-full h-full mt-8 lg:mt-0">
					<div className="flex flex-col items-center space-y-8 order-1 md:order-none">
						{/* Title */}
						<h1
							id="TITLE"
							className="text-4xl font-extrabold font-serif uppercase text-gray-800"
						>
							Your Water, Our Priority
						</h1>

						{/* Line */}
						<div
							id="LINE"
							className="w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 2xl:w-1/4 h-1 bg-red-700"
						></div>
						<p className="md:w-3/4 2xl:w-1/2 text-center lg:text-left text-lg mb-4">
							At Polar&nbsp;Water, we are dedicated to ensuring your home has access to clean, safe water without the hassle. Our comprehensive support and services are designed to keep your system running smoothly, year after year.
						</p>
					</div>
				</div>

				{/* Image Section */}
				<div
					id="IMAGES"
					className="flex flex-col space-y-8 md:space-y-0 lg:flex-row lg:space-x-4 mt-8"
				>
					{/* For small screens: Images in five rows */}
					<div className="flex flex-col space-y-4 md:hidden">
						{images.map((image) => (
							<div key={image.id} className="w-full">
								<img
									src={image.src}
									alt={image.alt}
									className="w-full h-auto rounded-lg shadow-lg"
								/>
							</div>
						))}
					</div>

					{/* For medium screens: 3 rows (1-2/3-4/5) */}
					<div className="hidden md:flex md:flex-col xl:hidden space-y-4">
						{/* First row: 1st and 2nd images */}
						<div className="flex space-x-4">
							<img
								src={images[0].src}
								alt={images[0].alt}
								className="w-1/2 h-auto rounded-lg shadow-lg"
							/>
							<img
								src={images[1].src}
								alt={images[1].alt}
								className="w-1/2 h-auto rounded-lg shadow-lg"
							/>
						</div>

						{/* Second row: 3rd and 4th images */}
						<div className="flex space-x-4">
							<img
								src={images[2].src}
								alt={images[2].alt}
								className="w-1/2 h-auto rounded-lg shadow-lg"
							/>
							<img
								src={images[3].src}
								alt={images[3].alt}
								className="w-1/2 h-auto rounded-lg shadow-lg"
							/>
						</div>
					</div>

					{/* For large screens: Centered row of 5 larger images */}
					<div className="hidden xl:flex justify-center items-center space-x-2 px-12 w-full">
						{images.map((image, index) => (
							<React.Fragment key={image.id}>
								{/* Image */}
								<img
									src={image.src}
									alt={image.alt}
									className="w-1/3 h-auto rounded-lg shadow-lg"
								/>
								{/* Vertical Line (add except after the last image) */}
								{index < images.length - 1 && (
									<div className="w-[2px] h-full bg-red-700 mx-2"></div>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default GuaranteeSection;
